@tailwind base;
@tailwind components;
@tailwind utilities;

.awards-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 0.5rem;
    margin: 0 auto;
}

.awards-column{
    align-items: center;
}

.awards-pic{
    border-radius:12px;
    box-shadow: 0 0 10px 3px gold;
}

.awards-font{
    width:200px;
    margin-top:20px;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: gold;
    transform-origin: 0%;
    color:transparent;
  }
  
  @media (max-width: 768px) {
   
    .awards-grid{
        max-width:95%;
        grid-template-columns: 1fr;
    }
  }


