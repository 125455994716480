.top-outreach-grid{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:0.5rem;
    margin: 0 auto;
    margin-top:50px;
    place-items: center;
}

.three-grid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:0.5rem;
    margin: 0 auto;
    margin-top:100px;
    width:95%;
    margin-bottom:100px;
}

.top-image{
    border-radius:8px;
    width:90%;
    box-shadow: 0 0 10px 3px gold;
}

.grid-image{
    min-width:20px;
    max-width: 350px;
    margin-left:25px;
    margin-right:25px;
    border-radius:8px;
    box-shadow: 0 0 10px 3px gold;
    margin-top:30px;
}

.three-grid-column1, .three-grid-column2, .three-grid-column3{
    border: 3px solid gold;
    border-radius:12px;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: gold;
    transform-origin: 0%;
    color:transparent;
  }

  @media (min-width: 340px) {
   
   
}

@media (max-width: 1024px) {
    .top-outreach-grid {
        grid-template-columns: 1fr, 1fr; 
        max-width: 80%;
      }

      .three-grid{
        max-width:80%;
        grid-template-columns: 1fr;
      }
  }

@media (max-width: 768px) {
    .top-outreach-grid, .three-grid {
      grid-template-columns: 1fr; 
      max-width: 80%;
    }
  
  }