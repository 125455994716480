@tailwind components;
@tailwind utilities;


.navbar{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    padding: 10px 20px;
    justify-content:space-between;
    background-color:transparent;
    border: none;
    border-radius:16px;
    margin-top:-10px;
    margin-bottom:-10px;
    
}

.nav-links{
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    gap:40px;
}


.navbarlink{
  text-decoration: none;
  font-weight:550;
  color:rgb(200, 170, 0);
}

.nav{
    background-color:transparent;
    margin:10px;
}

.logoimage{
    width:150px;
    margin-top:-20px;
    margin-bottom:-20px;
}

.login-button{
    background-color:black;
    color:white;
    border-radius:8px;
    font-size:1.5rem;
}

.login-button:hover{
    background-color:gold;
    cursor: pointer;
    padding:5px;
}

.button {
    color: gold;
    background-color: rgb(70, 70, 70); 
    font-weight: 700; /* font-medium */
    border-radius: 1rem; /* rounded-lg */
    font-size: 0.875rem; /* text-sm */
    padding: 0.6rem 1rem; /* px-4 py-2 */
    text-align: center;
    outline: none;
    border: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .button:hover {
    background-color: rgb(64, 64, 64); /* hover:bg-blue-800 */
    cursor:pointer;
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(98, 98, 98, 0.5); /* focus:ring-4 focus:ring-blue-300 */
  }
  
  .button:focus-visible {
    box-shadow: 0 0 0 4px rgba(147, 197, 253, 0.5); /* Similar to focus:ring-blue-300 */
  }
  
  .dark-mode .button {
    background-color: #2563eb; /* dark:bg-blue-600 */
  }
  
  .dark-mode .button:hover {
    background-color: #1e40af; /* dark:hover:bg-blue-700 */
  }
  
  .dark-mode .button:focus {
    box-shadow: 0 0 0 4px rgba(30, 58, 138, 0.5); /* dark:focus:ring-blue-800 */
  }

  .navbarlink:hover{
    color:gold;
  }

  .navbarlink:focus{
    color:gold;
    border-bottom:1px solid gold;
   }

 


  .navbarhr{
    border: none;
    height: 2px;
    background-color: #3d3d3d;
  }

  .displayname{
    margin-right:-25px;
  }

  .pfp{
    width: 2.5rem;
height: 2.5rem;
border-radius: 50%;
margin-right:-30px;
  }

  .logo-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    background-color: transparent;
    color: black;
    font-family: Inter, sans-serif;
  }

  @media (min-width: 340px) {
    .sponsors-section{
       max-width:95%;
       margin: 0 auto;
    }
}

@media (max-width: 1024px) {
    .sponsorsgrid{
        max-width:95%;
        grid-template-columns: 1fr 1fr;
    }
  }


@media (max-width: 768px) {
    .navbar {
        display: block; 
        max-width:95%;
        gap:0.5rem;
    }


}