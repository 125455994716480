.footerhr{
    width:80%;
}

.footerelement{
    margin:10px;
    list-style-type: none;
    color:white;
}
.footerlinks{
    text-decoration: none;
    color:white;
}


.footer-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
}

.footer {
    background-color: #181818;
    background-image: linear-gradient(180deg, #282828, rgb(142, 144, 0));
    padding: 2rem 0;
    margin-top: auto; 

  }


  
  .footer__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer__section {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .footer__section--main {
    text-align: center;
  }
  
  .footer__logo {
    max-width: 150px;
    height: auto;
    margin-bottom: -1rem;
  }
  
  .footer__mission {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }


  .gradient-text-footer {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(45deg, gray, gold); /* Adjust colors as needed */
  }

  .footerelementheading{
    list-style-type: none;
    font-size: 1.2rem;
    margin-bottom:20px;
    margin-top:-30px;
  }

  
.instagram-icon{
    size:5000em;
    vertical-align:middle;
}

.footer-grid{
    margin-top: -30px;
}

.footer-text{
    width:270px;
    min-height:30px;
}

.footer-heading-image{
    width:90px;
    min-width:10px;
    margin-bottom:20px;
    margin-top:-30px;
}
.footer-heading-image2{
    width:150px;
    min-width:10px;
    min-height:33px;
    margin-bottom:20px;
    margin-top:-33px;
}

.transparentext{
    color:transparent;
}

@media (max-width: 1024px) {
    .footer-column{
        grid-template-columns: 1fr;
    }
  }

@media (max-width: 768px) {
    .footer, .footer-column {
        display: block; 
        max-width:95%;
        gap:0.5rem;
    }


}



/**.footer-grid{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:-100rem;
}*/