.robotgrid{
    display:grid;
    grid-template-columns: 1fr 1fr;
}

.robotgridtext{
    display: grid;
    place-content: center;
}

/*claude*/

.slideshow {
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: 32rem; /* 64px * 4 = 256px = 16rem */
    background-color: #323232; /* gray-200 */
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .slideshow-content {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slideshow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 9999px;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .slideshow-button:hover {
    background-color: rgba(255, 255, 255, 0.75);
  }
  
  .slideshow-button-left {
    left: 0.5rem;
  }
  
  .slideshow-button-right {
    right: 0.5rem;
  }
  
  .slideshow-indicators {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  
  .slideshow-indicator {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.25rem;
    border-radius: 9999px;
    background-color: #9ca3af; /* gray-400 */
  }
  
  .slideshow-indicator-active {
    background-color: #3b82f6; /* blue-500 */
  }
  
  .slide-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .slide-text {
    font-size: 1.125rem;
  }
  
  .slide-image {
    max-width: 100%;
    max-height: 100%;
  }

  .slide-grid{
    display:grid;
    grid-template-columns: 1fr 1fr ;
    gap:50px;
  }