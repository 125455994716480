 .aboutusgrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 0.5rem;
    margin: 0 auto;
    background-color: transparent;
    color: white;
    font-family: Inter, sans-serif;
    width:100%;
    padding-bottom:100px;
    padding-top:100px;
    background-color: transparent; 
 }



 .aboutuspic{
    margin-right:20px;
    width:700px;
    border-radius:12px;
    box-shadow: 0 0 10px 3px gold;
 }

 .meettheteampic{
    border-radius:12px;
 }

 .member-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 0.5rem;
    background-color: transparent;
    width: 600px;
    margin: 0 auto;
    border-radius:12px;
 }

 .member-column{
    border:1px solid gold;
    border-radius:12px;
 }


ul li{
    list-style-position: inside;
    margin: 0 auto;
    text-align:center;
}
 ul li::marker{
    background-color:black;
 }

 .member-column-centered{
    margin: 0 auto;
    border:1px solid gold;
    border-radius:12px;
    width:392px;
    margin-top: 16px;
    margin-bottom:16px;
 }

 .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: gold;
    transform-origin: 0%;
    color:transparent;
  }

  .teampics{
    margin-bottom:20px;
  }

  .programming-grid, .engineering-grid, .documentation-grid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    width:90%;
    margin:0 auto;
  }

  .programming-member, .engineering-member, .documentation-member{
    border:1px solid gold;
    width:80%;
    margin: 0 auto;
    padding-top:20px;
    box-shadow: 0 0 10px 1px gold;
  }

 

  .programming-member-centered, .engineering-member-centered, .documentation-member-centered{
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 50px;
    width:80%;
  }



  .headshot{
    width:80%;
  }

  .aboutdescriptions{
    margin: 0 auto;
    width:70%;
    margin-bottom:15px;
    margin-top:-10px;
  }

  .aboutnames{
    color:gold;
  }

  .aboutheadinghr{
    width:20%;
    margin: 0 auto;
    margin-bottom:40px;
    margin-top:-5px;
  }


  @media (min-width: 340px) {
    
}

@media (max-width: 1024px) {
  }

  @media (max-width: 768px) {
    .programming-grid, .engineering-grid, .documentation-grid {
      grid-template-columns: 1fr; 
      max-width: 95%;
    }
  
    .programming-member, .engineering-member, .documentation-member, .programming-member-centered, .engineering-member-centered, .documentation-member-centered {
      height: auto; 
      min-height: 300px;
      width:80%;
      margin-bottom:2rem;
      margin-top:0rem;
    }
  
    .headshot {
      height: auto;
      max-width:80%;
    }
    .aboutusgrid{
        max-width:95%;
        grid-template-columns: 1fr;
    }
    .aboutuspic{
        max-width:95%;
    }

  }



  

 