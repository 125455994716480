.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  color:white;
  background-color:#282828;
}

@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
