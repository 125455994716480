.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: gold;
    transform-origin: 0%;
    color:transparent;
  }

  .firstdescription, .firstcontainer{
    margin-bottom:50px;
    margin-top:50px;
  }

  .yellowfirst{
    color:gold;
  }

  .ftcdescription{
    width:40%;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  @media (max-width: 768px) {
   
    .firstcontainer{
        max-width:95%;
        grid-template-columns: 1fr;
    }
    .firstvideo{
        max-width:80%;
    }
  }