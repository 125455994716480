.homepage-grid{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:1rem;
    margin: 0 auto;
    margin-top:50px;
    place-items: center;
}

.robot-image{
    width:80%;
    border-radius:12px;
    box-shadow: 0 0 10px 3px gold;
}
.parent-centered-container{
    background-image: url('../../images/robot.png');
    background-size: cover;
    /**background-size:cover;*/
    background-position: center;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .abouttheteam{
    margin-top:120px;
    /**background: linear-gradient(to bottom, #282828, rgb(61, 61, 61));*/
  }

  .centered-container{
    background-color: rgba(248, 241, 26, 0.8);
    width:70%;
    min-height: 400px;
    border-radius: 0px;
    padding: 20px;
    color:black;
  }

  .para{
    margin-left:40px;
    margin-right: 40px;
    margin-top:0px;
    margin-bottom:30px;
    color:gray;
  }

  .homehr{
    width:70%;
}

.ftcimage{
    width:500px;
}

.whatisparent{
    padding: 0.5rem;
    margin: 0 auto;
    background-color: transparent;
    color: black;
    font-family: Inter, sans-serif;
    width:75%;
    border-radius:12px;
    padding-bottom:50px;
    padding-top:50px;
    background-color: gold;
    box-shadow: 0 0 10px 3px gold;
}

.whatisright{
    align-items:center;
    width:55%;
    margin: 0 auto;
    margin-top:15px;
}


.panther-image{
    margin: -50px;
    margin-bottom:-120px;
    width:300px;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: gold;
    transform-origin: 0%;
    color:transparent;
  }

.progress-bar-background{
    background-color:transparent;
}

  .countdowntimer{
    border:1px solid gold;
    box-shadow: 0 0 10px 3px gold;
    width:35%;
    margin: 0 auto;
    margin-top:50px;
    padding-bottom:20px;
    border-radius: 12px;
    margin-bottom:20px;
  }

  .homedivide{
    width:80%;
    margin: 0 auto;
    margin-top:50px;
    margin-bottom:50px;
    border:none;
    height: 2px;
    background-color: #444444;
  }

  .tournament{
    margin-bottom:100px;
  }

  .vertical-line{
    width: 1px;         /* This makes the line thin (vertical) */
  height: 100px;      /* Adjust the height to your preference */
  background-color: black;  /* Line color */
  margin: 0 auto;    
  }

  @font-face {
    font-family: 'PantherRegular';
    src: url('../../components/fonts/panthera-font/PantheraRegular-MVZ9J.ttf') format('truetype');
  }
  
  
  .pantherfont {
    font-family: 'CustomFont', sans-serif; 
  }

  .panthera{
    width:80%;
  }

  .italicize{
    font-style: italic;
  }

  .button2 {
    color: white;
    background-color: #181818; 
    font-weight: 500; 
    border-radius: 1rem; 
    font-size: 0.875rem; 
    padding: 0.6rem 1rem; 
    text-align: center;
    outline: none;
    border: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .button2:hover {
    background-color: black; /* hover:bg-blue-800 */
    cursor:pointer;
  }
  
  .button2:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(98, 98, 98, 0.5); /* focus:ring-4 focus:ring-blue-300 */
  }

  .descriptionlogo{
    margin-bottom:-100px;
    width:300px;
  }

  .yellowtext{
    color:gold;
  }

  .sponsorimage1{
    height:150px;
    width:400px;

  } 

  .sponsorimage2{
    height:150px;
  }

  .sponsorimage3{
  
    height:150px;
  }

  .sponsor-column1{
    height:450px;
    display:grid;
    align-content: center;
    margin: 0 auto;
  }

  .sponsor-column2{
    height:450px;
    display:grid;
    align-content: center;
    margin: 0 auto;
  }

  .sponsor-column3{
    height:450px;
    display:grid;
    align-content: center;
    margin: 0 auto;
  }

  .sponsorsgrid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width:80%;
    margin: 0 auto;
  }

  .sponsor-links{
    color:gold;
  }

  .sponsors-text{
    text-decoration: underline;
    color:gold;
  }

  @media (min-width: 340px) {
    .whatisparent {
      max-width: 80%;
    }
    .ftcimage{
        max-width: 80%;
    }
   
}

@media (max-width: 1024px) {
    .sponsorsgrid{
        max-width:95%;
        grid-template-columns: 1fr 1fr;
    }
  }

@media (max-width: 768px) {
    .sponsorsgrid {
      grid-template-columns: 1fr; 
      max-width: 80%;
    }
  
    .sponsor-column {
      height: auto; 
      min-height: 300px;
    }
  
    .sponsorimage {
      height: auto;
      max-height: 150px;
    }
  }



  /*
  test comment
  */
